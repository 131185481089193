import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { smoothlyMenu } from "../../app.helpers";
import { Router } from '@angular/router';

@Component({
  selector: "app-topnavbar",
  templateUrl: "./topnavbar.component.html",
  styleUrls: ["./topnavbar.component.css"]
})
export class TopnavbarComponent implements OnInit {
  @Output("toggleSideMenu")
  toggleSideMenu = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit() { }
  toggleSideBar(){
    this.toggleSideMenu.emit();
    this.toggleNavigation()
  }
  toggleNavigation() {

    jQuery("body").toggleClass("sidebar-collapse");
    this.smoothlyMenu();
  }
  smoothlyMenu() {
    if (
      !jQuery("body").hasClass("sidebar-collapse") ||
      jQuery("body").hasClass("body-small")
    ) {
      // Hide menu in order to smoothly turn on when maximize menu
      jQuery("#side-menu").hide();
      // For smoothly turn on menu
      setTimeout(function () {
        jQuery("#side-menu").fadeIn(400);
      }, 200);
    } else if (jQuery("body").hasClass("fixed-sidebar")) {
      jQuery("#side-menu").hide();
      setTimeout(function () {
        jQuery("#side-menu").fadeIn(400);
      }, 100);
    } else {
      // Remove all inline style from jquery fadeIn function to reset menu state
      jQuery("#side-menu").removeAttr("style");
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }
}
