/**************************************
* Author: Sipra Software
* Date:20-08-2019
* Version:1.0
* Purpose: * created updatescroll function for messages div to scrolldown when new message is added
           * toggleCollapse function is to display message window when user clicks on icon and to minimize the message 
              window when user clicks on down arrow.
           * onClickUser function is for when agent click on a particular user it will display that user chat window and 
              along with the messages and time .
           * botGreetingAgent function is for display a greeting message when user clicks on icon and this function will 
             be called in onClickUser function.
           * created a function getusermessages which can get old messages information from userservice.ts file and 
             will be displayed on messages window.
           * sendMessage function is to send user input message to userservice.ts file.
           * called getMessages function to get messages from userservice.ts to display on messages window
**************************************/

import { Component, OnInit } from '@angular/core';
import { UserService } from './chatbot.service';
import { FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})

export class ChatbotComponent implements OnInit {
  message: string;
  messages: string[] = [];
  imgtrueboolean = true;
  chattextboolean = false;
  messAry = []
  replyMessage = "";
  userID: any;
  agentID: any;

  chatWindow: any;

  userData: any;

  agentData = {
    status: "",
    name: "'"
  };

  constructor(private UserService: UserService) {

    if (this.messAry && !this.messAry.length) {
      this.messAry.push({
        "text": "Hello! Welcome to chatbot",
        "self": false,
        "userID": "",
        msgtime: "",
        sender: ""
      })
    }

    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  ngOnInit() {

    this.UserService.connectUser();
    this.UserService
      .getAgentConnected()
      .subscribe((data: any) => {
        data.name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
        this.agentData = data;
      });

  }

  getUserMessages() {
    this.UserService
      .getUserMessages(this.userData.user_id)
      .pipe(first())
      .subscribe((response) => {
        this.messAry = [];
        response.forEach(value => {
          value.name = value.name.charAt(0).toUpperCase() + value.name.slice(1);
          this.messAry.push({
            "text": value.message,
            "self": (value.sender == "user") ? true : false,
            "userID": value.userID,
            msgtime: value.createdAt,
            sender: value.sender,
            name: (value.sender == "user") ? "You" : value.name
          })
        })

      });
  }

  sendMessage(message) {
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    this.messAry.push({
      "text": message,
      "self": true,
      msgtime: time,
      name: "You"
    });

    this.UserService.sendMessage({ agentID: this.agentID, message });
    this.message = '';
  }

  chatWindowToggle() {
    this.chatWindow = !this.chatWindow;
    if (this.chatWindow) {
      this.getUserMessages();
    }
  }

}










