import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { Login } from "../../models/login";
import { DataserviceService } from "../../services/dataservice.service"
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  @Input() loginInfo: Login;
  userData: any;
  submenus: any = [];
  constructor(private router: Router, private data: DataserviceService) {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  ngOnInit() {
    this.setSubmenus();
    $('body').bind('click', function (e: any) {
      var container = $("#sidebar");
      if (!container.is(e.target) && !container.has(e.target).length && !$(e.target).hasClass("fa-bars")) {
        if ($(".main-section").hasClass("sidemenu-collapse")) {
          $(".sidebar-dropdown").removeClass("active");
          $(".sidebar-submenu").slideUp(250);
        }
      }
    });
  }
  onclickTab(submenu, id) {
    $(".sidebar-submenu").slideUp(250);
    if (submenu.reports) {
      if ($(`#${id}`)
        .parent()
        .hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(`#${id}`).parent().addClass('active');
        $(`#${id}`).slideDown(250);
      }
    } else {
      $(".sidebar-dropdown").removeClass("active");
      this.router.navigate([`${submenu.routerLink}`]);
    }
  }

  hideSideNav() {
    if ($(".main-section").hasClass("sidemenu-collapse")) {
      $(".sidebar-dropdown").removeClass("active");
      $(".sidebar-submenu").slideUp(250);
    }
  }
  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }
  addclassone() { document.getElementById("rem").classList.add("active"); }
  addclass() { document.getElementById("rem").classList.add("active"); }
  removecls() { document.getElementById("remo").classList.remove("active"); }
  removeclsone() { document.getElementById("remo").classList.remove("active"); }
  setSubmenus() {
    this.submenus = this.data.getReports();
    this.submenus.forEach((submenu) => {
      switch (submenu.name) {
        case "Govt Forms":
          submenu.reports = submenu.reports.filter(report => this.userData.reportAccessVo.govermentForm.indexOf(report.name) > -1)
          break;
        case "Internal Forms":
          submenu.reports = submenu.reports.filter(report => this.userData.reportAccessVo.internalForm.indexOf(report.name) > -1)
          break;
        case "Benefit Reports":
          submenu.reports = submenu.reports.filter(report => this.userData.reportAccessVo.benefitReport.indexOf(report.name) > -1)
          break;
        case "Client Payroll Reports":
          submenu.reports = submenu.reports.filter(report => this.userData.reportAccessVo.clientPayrollReport.indexOf(report.name) > -1)
          break;
        case "Gl Accounting":
          submenu.reports = submenu.reports.filter(report => this.userData.reportAccessVo.glAccReport.indexOf(report.name) > -1)
          break;
        case "Payroll Automation":
          submenu.reports = submenu.reports.filter(report => this.userData.reportAccessVo.payrollAutomationReport.indexOf(report.name) > -1)
          break;
        case "Year End Reporting":
          submenu.reports = submenu.reports.filter(report => this.userData.reportAccessVo.yearEndReporting.indexOf(report.name) > -1)
          break;
        case "User Management":
          submenu = this.userData.roles[0] == 'Admin' ? submenu : submenu.hide = true;
          break;
        case "Online Services":
          submenu = this.userData.roles[0] == 'Admin' ? submenu : submenu.hide = true;
          break;
        case "Rates":
          submenu = this.userData.roles[0] == 'Admin' ? submenu : submenu.hide = true;
          break;
      }
    })
  }
}
