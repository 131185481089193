import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule } from "@angular/common";

import { HttpClientModule } from "@angular/common/http";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
 

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ng2-bootstrap/pagination';

import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ModalModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module
import { NgxPaginationModule } from 'ngx-pagination';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
  imports: [
    ChartsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2TableModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxSpinnerModule,
    SweetAlert2Module.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AngularMultiSelectModule,
    Ng2OrderModule,
    NgxPaginationModule,
    MultiselectDropdownModule,

  ],
  declarations: [
  ],
  exports: [
    ChartsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    Ng2TableModule,
    PaginationModule,
    BsDatepickerModule,
    DatepickerModule,
    NgxSpinnerModule,
    SweetAlert2Module,
    ModalModule,
    TabsModule,
    AngularMultiSelectModule,
    Ng2OrderModule,
    NgxPaginationModule,
    MultiselectDropdownModule,

  ],
})
export class SharedModule { }
