/**************************************
* Author: Sipra Software
* Date:20-08-2019
* Version:1.0
* Purpose: * Sending user information to backend when user connected from user's side window
           * created a function getUserMessages to call API for all messages of particular ID from db using http requests and returns 
                the result  
           * sendMessage function is to send user input messages to backend
           * getMessages function  can get the agent's input messages as user's output message from backend
             and will return the message .
          * getUserID function will convert random number from number to string
**************************************/

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private socket;
  messagesURL:string;
  welcomMessage:string;

  constructor(private http:HttpClient) {
    this.socket = io(`${environment.chatbotAPIUrl}`);
  }

  public connectUser(){
    let userData = JSON.parse(localStorage.getItem('userData'));
    this.socket.emit('adduser', {
      name: userData.firstname + " "+userData.lastName,
      userID: userData.user_id,
      email: userData.username
    });
  }
  
  public getUserMessages(id){
    this.messagesURL = `${environment.chatbotAPIUrl}/chats/usermessages/${id}`
    return this.http.get<any>(this.messagesURL)
    .pipe(map(reponse => {
      return reponse;
    }));
  }

  public sendMessage(request) {
     
    this.socket.emit('userinput',request);
  }

  public checkAgentOnline() {
    this.socket.emit('currentagent');
  }
  
  public getMessages = () => {
    return Observable.create((observer) => {
      this.socket.on('useroutput', (response) => {
        observer.next(response);
      });
    });
  }

  public getAgentConnected = () => {
    return Observable.create((observer) => {
      this.socket.on('currentagent', (response) => {
        observer.next(response);
      });
    });
  }

}

