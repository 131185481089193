import { Injectable } from '@angular/core';
import { CanActivate, Router, NavigationEnd, Event } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  previousUrl: string;
  constructor(private router: Router) {
    this.router.events.filter(event => event instanceof NavigationEnd)
      .subscribe((data: Event) => {
        if (this.previousUrl != "/dashboard/w2fedsummarydetails" && window.location.pathname == "/dashboard/w2fedsummary") {
          localStorage.removeItem('filterData');
        }
        if (data instanceof NavigationEnd)
          this.previousUrl = data.url;
      });
  }

  canActivate() {
    localStorage.getItem('bearerToken');
    if (localStorage.getItem('bearerToken')) {
      return true;

    } else {
      this.router.navigateByUrl('');
      return false;
    }
  }
}
