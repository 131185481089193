import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'revolution-payroll';
  userActivity;
  userInactive: Subject<any> = new Subject();
  constructor(private router: Router) {
    const now = new Date().toLocaleString()
    console.log('New Build', now)
    this.setTimeout();
    this.userInactive.subscribe((res: any) => {
      alert("Your session has timed out. Please log in again.");
      this.router.navigate(['./']),
        localStorage.removeItem('userData');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('bearerToken');
    }
    )
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1800000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('window:beforeunload') goToPage() {
    console.log('reloaded')
  }
}
