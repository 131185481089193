import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthGuard } from './services/auth-guard.service';
const appRoutes: Routes = [
  { path: '', loadChildren:'./components/login/login.module#LoginModule' },
  { path: 'resetpassword', loadChildren:'./components/resetpassword/resetpassword.module#ResetpasswordModule' },
  { path: 'confirmotp', loadChildren:'./components/confirmotp/confirmotp.module#ConfirmotpModule' },
  { path: 'confirmpassword', loadChildren:'./components/confirmpassword/confirmpassword.module#ConfirmpasswordModule' },
  { path: 'setpassword', loadChildren:'./components/setpassword/setpassword.module#SetpasswordModule' },

  { path: 'dashboard', component: LayoutComponent, canActivate: [AuthGuard],
    children: [
    { path: '', loadChildren:'./pages/dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
    
    // usermanagement
    { path: 'usermanagement', loadChildren:'./pages/users/users.module#UsersModule', canActivate: [AuthGuard] },
    { path: 'onlineservices', loadChildren:'./pages/online-services/online-services.module#OnlineServicesModule', canActivate: [AuthGuard] },
    { path: 'rates', loadChildren:'./pages/masterrates/masterrates.module#MasterratesModule', canActivate: [AuthGuard] },
    { path: 'inviteuser', loadChildren:'./pages/inviteuser/inviteuser.module#InviteuserModule', canActivate: [AuthGuard] },
    
    // Govt Forms
    { path: 'form941', loadChildren:'./pages/govtForms/form941/form941.module#Form941Module', canActivate: [AuthGuard] },
    { path: 'form940', loadChildren:'./pages/govtForms/form940/form940.module#Form940Module', canActivate: [AuthGuard] },
    { path: 'form1099misc', loadChildren:'./pages/govtForms/form1099misc/form1099misc.module#Form1099miscModule', canActivate: [AuthGuard] },
    { path: 'form940detail/:id', loadChildren:'./pages/govtForms/form940detail/form940detail.module#Form940detailModule' },
    { path: 'form941detail/:id', loadChildren:'./pages/govtForms/form941detail/form941detail.module#Form941detailModule'  },
    { path: 'form1099miscdetail/:id', loadChildren:'./pages/govtForms/form1099miscdetail/form1099miscdetail.module#Form1099miscdetailModule' },
    { path: 'formw2', loadChildren:'./pages/govtForms/formw2/formw2.module#Formw2Module' },
    { path: 'form592b', loadChildren:'./pages/govtForms/from592b/form592b.module#From592bModule' },
    
    // internal forms 
    { path: 'w2fedsummary', loadChildren:'./pages/internalForms/w2fedsummary/w2fedsummary.module#W2fedsummaryModule', canActivate: [AuthGuard] },
    { path: 'w2statesummary', loadChildren:'./pages/internalForms/w2statesummary/w2statesummary.module#W2StatesummaryModule', canActivate: [AuthGuard] },
    { path: 'formquarterlyfutawagesummary', loadChildren:'./pages/internalForms/quarterlyfutawages/quarterlyfutawagesummary.module#quarterlyfutawagesummaryModule', canActivate: [AuthGuard] },
    { path: 'overunderfica', loadChildren:'./pages/internalForms/overunderfica/overunderfica.module#OverunderficaModule', canActivate: [AuthGuard] },
    { path: 'overundersdi', loadChildren:'./pages/internalForms/overundersdi/overundersdi.module#OverundersdiModule', canActivate: [AuthGuard] },
    { path: 'negativewages', loadChildren:'./pages/internalForms/negativewages/negativewages.module#NegativewagesModule', canActivate: [AuthGuard] },
    { path: 'loanoutwithholding', loadChildren:'./pages/internalForms/loanoutwithholding/loanoutwithholding.module#loanoutwithholdingModule', canActivate: [AuthGuard] },
    { path: 'federaltaxliability', loadChildren:'./pages/internalForms/federaltaxliability/federaltaxliability.module#federaltaxliabilityModule', canActivate: [AuthGuard] },
    { path: 'futataxliabilityactual', loadChildren:'./pages/internalForms/futataxliabilityactual/futataxliabilityactual.module#futataxliabilityactualModule', canActivate: [AuthGuard] },
    { path: 'futataxliabilitybilled', loadChildren:'./pages/internalForms/futataxliabilitybilled/futataxliabilitybilled.module#futataxliabilitybilledModule', canActivate: [AuthGuard] },
    { path: 'sutataxliabilityactual', loadChildren:'./pages/internalForms/sutataxliabilityactual/sutataxliabilityactual.module#sutataxliabilityactualModule', canActivate: [AuthGuard] },
    { path: 'sutataxliabilitybilled', loadChildren:'./pages/internalForms/sutataxliabilitybilled/sutataxliabilitybilled.module#sutataxliabilitybilledModule', canActivate: [AuthGuard] },
    { path: 'localtaxliability', loadChildren:'./pages/internalForms/localtaxliability/localtaxliability.module#LocaltaxliabilityModule', canActivate: [AuthGuard] },
    { path: 'statetaxliability', loadChildren:'./pages/internalForms/statetaxliability/statetaxliability.module#statetaxliabilityModule', canActivate: [AuthGuard] },
    { path: 'dailyfedtax', loadChildren:'./pages/internalForms/dailyfederaltaxsummary/dailyfederaltaxsummary.module#dailyfederaltaxsummaryModule', canActivate: [AuthGuard] },
    { path: 'dailystatetax', loadChildren:'./pages/internalForms/dailystatetaxsummary/dailystatetaxsummary.module#DailystatetaxsummaryModule', canActivate: [AuthGuard] },
    { path: 'newhire', loadChildren:'./pages/internalForms/newhire/newhire.module#newhireModule', canActivate: [AuthGuard] },
    { path: 'combinedtaxreport', loadChildren:'./pages/internalForms/combinedtaxreport/combinedtaxreport.module#combinedtaxreportModule', canActivate: [AuthGuard] },
    { path: 'configinterface', loadChildren:'./pages/internalForms/outbound-inbound/outbound-inbound.module#OutboundInboundeModule', canActivate: [AuthGuard] },
    { path: 'viewallinterface', loadChildren:'./pages/internalForms/outbound-inbound-details/outbound-inbound-details.module#OutboundInboundeDetailsModule', canActivate: [AuthGuard] },
    
    //benfit Report
    { path: 'unioncontributioncheckrequest', loadChildren:'./pages/benfitreports/unioncontributioncheckrequest/unioncontributioncheckrequest.module#UnioncontributioncheckrequestModule', canActivate: [AuthGuard] },
    { path: 'unioncontributionlist', loadChildren:'./pages/benfitreports/unioncontributionlist/unioncontributionlist.module#UnioncontributionlistModule', canActivate: [AuthGuard] },
    { path: 'payrollbenefitsregister', loadChildren:'./pages/benfitreports/payrollbenefitsregister/payrollbenefitsregister.module#PayrollbenefitsregisterModule', canActivate: [AuthGuard] },
    { path: 'employeepayrollbenefitsregister', loadChildren:'./pages/benfitreports/employeepayrollbenefitsregister/employeepayrollbenefitsregister.module#EmployeepayrollbenefitsregisterModule', canActivate: [AuthGuard] },
    { path: 'mpiphpbackupreport', loadChildren:'./pages/benfitreports/mpiphpbackupreport/mpiphpbackupreport.module#MpiphpbackupreportModule', canActivate: [AuthGuard] },
    { path: 'genericreport', loadChildren:'./pages/benfitreports/aftrareport/aftrareport.module#AftrareportModule', canActivate: [AuthGuard] },
    { path: 'genericreportByClient', loadChildren:'./pages/benfitreports/generic-report-by-client/generic-report-by-client.module#GenericReportByClientModule', canActivate: [AuthGuard] },
    { path: 'dga', loadChildren:'./pages/benfitreports/dga/dga.module#DGAModule', canActivate: [AuthGuard] },
    { path: 'dga-vacation', loadChildren:'./pages/benfitreports/dga-vacation/dga-vacation.module#DgaVacationModule', canActivate: [AuthGuard] },
    { path: 'freelancedgavac', loadChildren:'./pages/benfitreports/freelancedgavac/freelancedgavac.module#FreelancedgavacModule', canActivate: [AuthGuard] },
    { path: 'freelancesalary', loadChildren:'./pages/benfitreports/freelancesalary/freelancesalary.module#FreelancesalaryModule', canActivate: [AuthGuard] },
    { path: 'ianbf', loadChildren:'./pages/benfitreports/iabnf/iabnf.module#IABNFModule', canActivate: [AuthGuard] },
    { path: 'local476', loadChildren:'./pages/benfitreports/iabnfsecondversion/iabnfsecondversion.module#IabnfsecondversionModule', canActivate: [AuthGuard] },
    { path: 'mpirgcoversheet', loadChildren:'./pages/benfitreports/mpirgcoversheet/mpirgcoversheet.module#MpirgcoversheetModule', canActivate: [AuthGuard] },
    { path: 'mpiprategroupbiweekly', loadChildren:'./pages/client-detail-reports/mpipbyweekly/mpipbyweekly.module#MpipByWeeklyModule', canActivate: [AuthGuard] },
    { path: 'phbpcontributions', loadChildren:'./pages/benfitreports/phbpcontributions/phbpcontributions.module#PhbpcontributionsModule', canActivate: [AuthGuard] },
    { path: 'pensioncontributionsdue', loadChildren:'./pages/benfitreports/pension/pension.module#PensionModule', canActivate: [AuthGuard] },
    { path: 'localdues', loadChildren:'./pages/benfitreports/local-dues/local-dues.module#LocalDuesModule', canActivate: [AuthGuard] },
    { path: 'localhealth', loadChildren:'./pages/benfitreports/local-health/local-health.module#LocalHealthModule', canActivate: [AuthGuard] },
    { path: 'localpension', loadChildren:'./pages/benfitreports/local-pension/local-pension.module#LocalPensionModule', canActivate: [AuthGuard] },
    { path: 'sagresiduals', loadChildren:'./pages/benfitreports/sag-residuals/sag-residuals.module#SagResidualsModule', canActivate: [AuthGuard] },
    { path: 'phbp', loadChildren:'./pages/benfitreports/phbp/phbp.module#PhbpModule', canActivate: [AuthGuard] },
    // { path: 'checkrequest', loadChildren:'./pages/benfitreports/checkrequest/checkrequest.module#checkrequestModule', canActivate: [AuthGuard] },

    // client details reports  
    { path: 'employeecheckstubhistory', loadChildren:'./pages/client-detail-reports/employeecheckstubhistory/employeecheckstubhistory.module#EmployeecheckstubhistoryModule', canActivate: [AuthGuard] },
    { path: 'payrollwageregister', loadChildren:'./pages/client-detail-reports/payrollwageregister/payrollwageregister.module#PayrollwageregisterModule', canActivate: [AuthGuard] },
    { path: 'customaggregatefringerreport', loadChildren:'./pages/client-detail-reports/customaggregatefringereport/customaggregatefringereport.module#CustomaggregatefringereportModule', canActivate: [AuthGuard] },
    { path: 'customtimeentryreport', loadChildren:'./pages/client-detail-reports/customtimeentryreport/customtimeentryreport.module#CustomtimeentryreportModule', canActivate: [AuthGuard] },
    { path: 'fringerecap', loadChildren:'./pages/client-detail-reports/fringerecap/fringerecap.module#FringerecapModule', canActivate: [AuthGuard] },
    { path: 'detailpayrolledit', loadChildren:'./pages/client-detail-reports/accountdetailspayrolledit/accountdetailspayrolledit.module#AccountdetailspayrolleditModule', canActivate: [AuthGuard] },
    { path: 'accountdetailsbyjob', loadChildren:'./pages/client-detail-reports/accountdetailsbyjob/accountdetailsbyjob.module#AccountdetailsbyjobModule', canActivate: [AuthGuard] },
    { path: 'prdedregisteremployee', loadChildren:'./pages/client-detail-reports/prdedregisteremployee/prdedregisteremployee.module#PrdedregisteremployeeModule', canActivate: [AuthGuard] },
    { path: 'prdedregisterbydeduction', loadChildren:'./pages/client-detail-reports/prdedregisterbydeduction/prdedregisterbydeduction.module#PrdedregisterbydeductionModule', canActivate: [AuthGuard] },
    { path: 'detailpayrecap', loadChildren:'./pages/client-detail-reports/detailpayrecap/detailpayrecap.module#DetailpayrecapModule', canActivate: [AuthGuard] },
    { path: 'simplecheckregister', loadChildren:'./pages/client-detail-reports/simplecheckregister/simplecheckregister.module#SimplecheckregisterModule', canActivate: [AuthGuard] },
    { path: 'accountdetails', loadChildren:'./pages/client-detail-reports/accountdetail/accountdetail.module#AccountdetailModule', canActivate: [AuthGuard] },
    { path: 'shortfallbydeduction', loadChildren:'./pages/client-detail-reports/shortfallbydeduction/shortfallbydeduction.module#ShortfallbydeductionModule', canActivate: [AuthGuard] },
    { path: 'shortfallbyemployeededuction', loadChildren:'./pages/client-detail-reports/shortfallbyemployeededuction/shortfallbyemployeededuction.module#ShortfallbyemployeedeductionModule', canActivate: [AuthGuard] },
    { path: 'employeehistory', loadChildren:'./pages/client-detail-reports/employeehistory/employeehistory.module#EmployeehistoryModule', canActivate: [AuthGuard] },
    { path: 'wcauditreport', loadChildren:'./pages/client-detail-reports/wcauditreport/wcauditreport.module#WcauditreportModule', canActivate: [AuthGuard] },
    { path: 'holidayaccrualreport', loadChildren:'./pages/client-detail-reports/holidayaccrualreport/holidayaccrualreport.module#HolidayaccrualreportModule', canActivate: [AuthGuard] },
    { path: 'sickvacationaccualreport', loadChildren:'./pages/client-detail-reports/sickvacationaccrualreport/sickvacationaccrualreport.module#SickvacationaccrualreportModule', canActivate: [AuthGuard] },
    { path: 'sickvacationaccualdetailreport', loadChildren:'./pages/client-detail-reports/sickvacationaccrualdetailreport/sickvacationaccrualdetailreport.module#SickvacationaccrualdetailreportModule', canActivate: [AuthGuard] },
    { path: 'payrollcheckregister', loadChildren:'./pages/client-detail-reports/payrollcheckregister/payrollcheckregister.module#PayrollcheckregisterModule', canActivate: [AuthGuard] },
    { path: 'sytycd', loadChildren:'./pages/client-detail-reports/sytycd/sytycd.module#SytycdModule', canActivate: [AuthGuard] },
    { path: 'sagaftra', loadChildren:'./pages/client-detail-reports/sagaftra/sagaftra.module#SagaftraModule', canActivate: [AuthGuard] },
    { path: 'incentive', loadChildren:'./pages/client-detail-reports/incentive/incentive.module#IncentiveModule', canActivate: [AuthGuard] },
    { path: 'mpip', loadChildren:'./pages/client-detail-reports/mpip/mpip.module#MpipModule', canActivate: [AuthGuard] },
    { path: 'employeecheckstubdetailhistory', loadChildren:'./pages/client-detail-reports/employeehistory/employeehistory.module#EmployeehistoryModule', canActivate: [AuthGuard] },
    { path: 'invoice-register', loadChildren:'./pages/client-detail-reports/Invoice-Register/invoice-register.module#InvoiceRegisterModule', canActivate: [AuthGuard] },
    { path: 'crewaddresslist', loadChildren:'./pages/client-detail-reports/crewaddresslist/crewaddresslist.module#crewaddresslistModule', canActivate: [AuthGuard] },
    { path: 'union-affiliation', loadChildren:'./pages/client-detail-reports/union-affiliation/union-affiliation.module#unionaffiliationModule', canActivate: [AuthGuard] },
    { path: 'detailearnings', loadChildren:'./pages/client-detail-reports/detailearnings/detailearnings.module#detailearningsModule', canActivate: [AuthGuard] },
    { path: 'summaryearnings', loadChildren:'./pages/client-detail-reports/summaryearnings/summaryearnings.module#summaryearningsModule', canActivate: [AuthGuard] },

    // glacconting 
    { path: 'glentryrecap', loadChildren:'./pages/glaccounting/glentry/glentry.module#glentryModule', canActivate: [AuthGuard] },
    { path: 'glentryrecapsummary', loadChildren:'./pages/glaccounting/glentry-summary/glentrysummary.module#glentrysummaryModule', canActivate: [AuthGuard] },
    { path: 'payrollcheckregisterreport', loadChildren:'./pages/glaccounting/payrollcheckregister/payrollcheckregister.module#payrollcheckregisterModule', canActivate: [AuthGuard] },
    { path: 'payrollcheckregisterreportsummary', loadChildren:'./pages/glaccounting/payrollcheckregistersummary/payrollcheckregistersummary.module#payrollcheckregistersummaryModule', canActivate: [AuthGuard] },
    { path: 'PositivePay', loadChildren:'./pages/glaccounting/PositivePayReport/PositivePayReport.module#PositivePayReportModule', canActivate: [AuthGuard] },
    { path: 'postedpayroll', loadChildren:'./pages/glaccounting/PostedPayrollReport/PostedPayrollReport.module#PostedPayrollReportModule', canActivate: [AuthGuard] },
    { path: 'arentry', loadChildren:'./pages/glaccounting/arentry/arentry.module#arentryReportModule', canActivate: [AuthGuard] },
    { path: 'rebatereport', loadChildren:'./pages/glaccounting/rebate/rebate.module#RebateReportModule', canActivate: [AuthGuard] },
    { path: 'hoursworkedbystatereport', loadChildren:'./pages/glaccounting/HoursWorkedByStateReport/HoursWorkedByStateReport.module#HoursWorkedByStateReportModule', canActivate: [AuthGuard] },
    { path: 'clientterms', loadChildren:'./pages/glaccounting/clientterms/clientterms.module#clienttermsModule', canActivate: [AuthGuard] },
    { path: 'glentryreport', loadChildren:'./pages/glaccounting/glentryreport/glentryreport.module#glentreyreportModule', canActivate: [AuthGuard] },
    { path: 'commissions', loadChildren:'./pages/glaccounting/commissions/commissions.module#commissionsModule', canActivate: [AuthGuard] },
    { path: 'balance', loadChildren:'./pages/glaccounting/balance/balance.module#balanceModule', canActivate: [AuthGuard] },
    { path: 'profitlossstatement', loadChildren:'./pages/glaccounting/profitlossstatement/profitlossstatement.module#profitlossstatementModule', canActivate: [AuthGuard] },
    { path: 'wcsummary', loadChildren:'./pages/glaccounting/wcsummary/wcsummary.module#wcsummaryModule', canActivate: [AuthGuard] },
    { path: 'wcdetail', loadChildren:'./pages/glaccounting/wcdetail/wcdetail.module#wcdetailModule', canActivate: [AuthGuard] },
    { path: 'funded', loadChildren:'./pages/glaccounting/funded/funded.module#fundedModule', canActivate: [AuthGuard] },


    //W2 Reporting
    { path: 'w2report', loadChildren:'./pages/w2reporting/w2/w2.module#W2ReportingModule', canActivate: [AuthGuard] },
    { path: 'report1099', loadChildren:'./pages/w2reporting/report1099/report1099.module#Report1099Module', canActivate: [AuthGuard] },
    { path: 'report592b', loadChildren:'./pages/w2reporting/report592b/report592b.module#Report592BModule', canActivate: [AuthGuard] },


   ]
  },
  { path: '**', loadChildren:'./pages/pagenotfound/pagenotfound.module#PageNotFoundModule' },
]




export const routing = RouterModule.forRoot(appRoutes);

