import { Component, OnInit } from '@angular/core';
import { Login } from '../../models/login';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  loginInfo: Login = {
    first_name: 'Revolutiones',
    last_name: 'Entertaimnet services',
    avatar: 'ay.jpeg',
    title: 'Payroll'
  };
  userData: any;
  sideMenu = true;
  constructor() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  ngOnInit() {
    jQuery(document).ready(function($) {
      var alterClass = function() {
        var ww = document.body.clientWidth;
        if (ww < 1435) {
          $('.main-section').addClass('sidemenu-collapse');
        } else if (ww >= 1434) {
          $('.main-section').removeClass('sidemenu-collapse');
        };
      };
      $(window).resize(function(){
        alterClass();
      });
      //Fire it when the page first loads:
      alterClass();
    });
  }

  toggleSidebar(){
    this.sideMenu = !this.sideMenu;
  }
}
